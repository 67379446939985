<template>
	<div>
		<div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
			<div class="carousel-inner">
				<div v-for="(item, index) in sliders" :key="index" class="carousel-item" :class="index == 0 ? 'active' : ''">
					<img :src="baseUrl + item.image" class="d-block w-100" alt="" />
				</div>
			</div>
			<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Previous</span>
			</button>
			<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="visually-hidden">Next</span>
			</button>
		</div>


		<!-- packages start -->
		<div class="section-block mpt-0">
			<div class="container">
				<div class="section-heading center-holder">
					<p class="packeg-header">{{ about.package_title }}</p>
					<div class="section-heading-line"></div>
					<div class="row">
						<div v-for="(item, index) in packages" :key="index" class="col-md-3 col-sm-4 col-12">
							<div class="pricing-list-2 border-0 shadow-none" style="transition: background-color 0.3s ease">
								<div class="extra-box">
									<div class="text-center">
										<!-- <img src="{{ asset('assets/img/cloud-computing.svg') }}" alt="Cloud Computing" style="width:50px!important;"> -->
										<h4>{{ item.name }}</h4>
									</div>
									<!-- <h4>{{ $portfolio->name }}</h4> -->

									<div class="pricing-list-2-price">
										<h3 style="color: #fff">{{ item.course_fee }}</h3>
										<span>/Course</span>
									</div>
								</div>
								<ul class="pricing-list-2-prod">
									<li>Application Fee : {{ item.application_fee }}</li>
									<li>Course Fee : {{ item.course_fee }}</li>
									<li>Duration : {{ item.duration }}</li>
									<li><router-link :to="{ name: 'contact' }"><span class="btn btn-sm btn-grad pck">Apply Now</span></router-link></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Package end -->

		<!-- Services start -->
		<div class="section-block-bg section-md services-block services-block-red mpt-0">
			<div class="container">
				<div class="row">
					<div class="col-md-12 col-sm-6 col-12 back-picture">
						<h2 class="text-center service-sec">We Offer</h2>
						<br />
						<p class="text-center packeg-header">{{ about.service_title }}</p>
						<br />
						<div class="row">
							<div v-for="(service, index) in services" :key="index" class="col-md-3 col-sm-3 col-12 d-flex">
								<div class="feature-box w-100 d-flex flex-column justify-content-between text-center">
									<!-- <i class="{{ $service->victor_name }}" style="color:#000; font-size: 70px !important;"></i> -->
									<img class="align-self-center" :src="baseUrl + service.image" style="width: 100px" alt="" />
									<h4>{{ service.name }}</h4>
									<p class="text-dark">{{ service.short_description }}</p>
									<div class="text-center mt-3">
										<router-link :to="`/service_details/${service.category_id}`" class="learn-more btn btn-sm"> Learn more <i class="learn-angle fa fa-caret-right"></i> </router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Services end -->

		<!-- Services start -->
		<div class="section-block-bg section-md services-block services-block-red mpt-0">
			<div class="container">
				<div class="section-heading center-holder">
					<h2 class="text-center service-sec">Accredited by</h2>
					<div class="section-heading-line"></div>
					<div style="display: none" :style="{ display: partners.length > 0 ? '' : 'none' }" class="owl-carousel owl-theme owl-loaded">
						<div class="owl-stage-outer">
							<div class="owl-stage">
								<div v-for="(partner, index) in partners" :key="index" class="owl-item">
									<img class="partner_img" :src="baseUrl + partner.image" :alt="partner.name" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Services end -->

		<!-- <carousel-page :partners="partners"></carousel-page> -->

		<!-- ABOUT start -->
		<div class="section-block pb-0 mpt-0">
			<div class="container">
				<div class="row">
					<div class="col-md-4 col-sm-4 col-12 mt-n3 mmt-0">
						<div class="contact-info">
							<h5 class="address-section">UK Office Address:</h5>
							<ul>
								<li><strong>Address</strong> : {{ about.uk_address }}</li>
								<li><strong>Mobile</strong> : {{ about.uk_mobile }}</li>
								<li><strong>Email</strong> : {{ about.uk_email }}</li>
							</ul>
						</div>
						<hr />
						<div class="contact-info">
							<h5 class="address-section">Portugal Office Address:</h5>
							<ul>
								<li><strong>Address</strong> : {{ about.portugal_address }}</li>
								<li><strong>Mobile</strong> : {{ about.portugal_mobile }}</li>
								<li><strong>Email</strong> : {{ about.portugal_email }}</li>
							</ul>
						</div>
					</div>

					<div class="col-md-8 col-sm-8 col-12 mt-n3 mmt-0">
						<h4 class="address-section">Contact US</h4>
						<form v-on:submit.prevent="saveData" class="contact-form myform mt-1">
							<div class="row">
								<div class="col-md-12 col-sm-6 col-12">
									<input type="text" v-model="message.name" placeholder="Name *" />
								</div>

								<div class="col-md-12 col-sm-6 col-12">
									<input type="email" v-model="message.email" placeholder="E-mail" />
								</div>

								<div class="col-md-12 col-sm-6 col-12">
									<input type="text" v-model="message.mobile" placeholder="Ex. 01xxxxxxxxxx *" />
								</div>

								<div class="form-group col-md-12 custom-lavel col-sm-6 col-12">
									<select class="form-control select2" v-model="message.subject">
										<option value="">Choose Subject</option>
										<option value="1">For New Or Change Connection</option>
										<option value="2">Others</option>
									</select>
								</div>

								<div class="col-md-12">
									<textarea v-model="message.message" placeholder="Message *"></textarea>
								</div>

								<div class="form-group col-md-12 text-center">
									<button type="submit" class="btn btn-dark mt-4 px-5 py-2">Save</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<br />
		<br />
		<!-- ABOUT END -->


		<div>
			<iframe class="w-100" src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d9924.496144063665!2d0.018678171714479212!3d51.54762454259613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x47d8a7e71c768333%3A0x2b0e0f1df26b6634!2sDurning%20Hall%2C%20London%20E7%209AB%2C%20United%20Kingdom!3m2!1d51.5488794!2d0.024061!5e0!3m2!1sen!2sbd!4v1728998270801!5m2!1sen!2sbd" allowfullscreen style="height: 300px"> </iframe>
		</div>


	</div>
</template>

<script>
import axios from "axios";

// import CarouselPage from "@/components/carouselPage.vue";
export default {
	name: "HomeView",
	props: {
		msg: String,
	},
	// components: {
	// 	CarouselPage
	// },
	data() {
		return {
			message: {
				name: '',
				email: '',
				mobile: '',
				subject: '',
				message: '',
			},
			about: [],
			services: [],
			partners: [],
			packages: [],
			sliders: [],
			baseUrl: this.$appUrl,
		};
	},
	async created() {
		await this.getData();
		await this.initOwlCarousel();
	},
	methods: {
		initOwlCarousel() {
			$(".owl-carousel").owlCarousel({
				// items: 4,
				// loop: true,
				autoplay: true,
				// margin: 10,
				// autoplayTimeout: 3000,
				// autoplayHoverPause: true

				loop: true,
				margin: 10,
				// nav:true,
				responsive: {
					0: {
						items: 1,
					},
					600: {
						items: 3,
					},
					1000: {
						items: 4,
					},
				},
			});
		},
		async getData() {
			try {
				const response = await axios.get("get-home-data");
				this.about = response.data.about;
				this.services = response.data.services;
				this.partners = response.data.partners;
				this.packages = response.data.packages;
				this.sliders = response.data.sliders;
			} catch (error) {
				console.error(error);
			}
		},
		async saveData() {

			if (this.message.name == '') {
				alert('Name is required!')
				return
			}
			if (this.message.mobile == '') {
				alert('Mobile is required!')
				return
			} else {
				if (!this.validateMobileNumber()) {
					alert('Valid Mobile is required!')
					return
				}
			}
			if (this.message.message == '') {
				alert('Message is required!')
				return
			}

			axios.post("save-message-home", this.message).then(res => {
				alert(res.data.message)
				if (res.data.status) {
					this.clearForm();
				}
			})

		},

		validateMobileNumber() {
			let pattern = /^\+?(88)?0(19|14|17|13|18|16|15)\d{8}$/
			return this.message.mobile.match(pattern) ? true : false;
		},
		clearForm() {
			this.message = {
				name: '',
				email: '',
				mobile: '',
				subject: '',
				message: '',
			}
		}

	},
};
</script>

<style scoped>
div[data-v-9ea40744] {
	background: transparent;
}

.partner_img {
	height: 86px !important;
	width: 161px !important;
	margin: 0 auto;
}

body {
	background-color: #ffffff !important;
}

.pricing-list-2 {
	/* background: linear-gradient(to right, #B77229, #795D24) !important; */
	/* transition: background-color 0.10s ease !important; */
	background-color: #f6f7fa;
	padding: 0px 0px 0 !important;
	-webkit-box-shadow: 0px 3px 10px 1px #888888a8 !important;
	-moz-box-shadow: 0px 3px 10px 1px #888888a8 !important;
	box-shadow: 0px 3px 10px 1px #888888a8 !important;
}

.extra-box {
	padding: 2px 7px 13px 7px !important;
	background-color: #fd8129 !important;
	border-radius: 15px 15px 111px 0;
}

ul.pricing-list-2-prod {
	padding: 0 20px 20px 20px;
}

.pricing-list-2-prod li:after {
	background: linear-gradient(to right, #b7722900, #795d2400) !important;
}

.pricing-list-2-prod li {
	color: #000 !important;
	line-height: 24px !important;
	padding: 0px 0px !important;
	font-size: 13px !important;
}

.packeg-header {
	color: #000 !important;
	font-size: 18px !important;
}

.btn-custom {
	padding: 13px 38px;
	border-radius: 26px;
}

.services-block .feature-box {
	border: 1px solid #d3e3fd8c !important;
}

.pricing-list-2-price h3 sup {
	color: #fff !important;
}

.pricing-list-2-price span {
	color: #fff !important;
}

.feature-box p {
	color: #000 !important;
	font-size: 17px !important;
	font-weight: 400 !important;
}

.feature-box {
	/* background: linear-gradient(to right, #B77229, #795D24) !important; */
	background-color: #fff0;
}

.feature-box:hover {
	/* background: #a020f0 !important; */
	-webkit-box-shadow: 0px 3px 10px 1px #888888a8 !important;
	-moz-box-shadow: 0px 3px 10px 1px #888888a8 !important;
	box-shadow: 0px 3px 10px 1px #888888a8 !important;
}

.feature-box h4 {
	color: #000 !important;
}

.swiper-pagination-bullet-active {
	background: #a7cf5d !important;
}

.swiper-pagination-bullet {
	width: 11px !important;
	height: 11px !important;
}

.help-block {
	color: red;
}

.section-block {
	background-color: #ffffff !important;
}

.section-heading h3 {
	background-image: linear-gradient(to right, #b77229, #795d24);
	background-clip: text;
	-webkit-background-clip: text;
	/* For Safari and older Chrome versions */
	color: transparent;
}

.section-heading h3 {
	line-height: 67% !important;
}

.pricing-list-2-price h3 {
	font-size: 38px !important;
	font-weight: 600 !important;
}

.pricing-list-2-price {
	margin-top: 0px !important;
}

.pricing-list-2 h4 {
	color: #ffffff !important;
}

/* service */


.primary-button {
	background: linear-gradient(to right, #b77229, #795d24) !important;
}

.primary-button:hover {
	background: #a020f0 !important;
}

.contact-form textarea {
	min-height: 102px !important;
}

.text-dark {
	color: #2f1a6c !important;
}

.learn-more {
	color: #fff !important;
	background-color: #fd8129;
	border-radius: 26px;
	padding: 6px 10px;
}

.contact-form input {
	background: #fff !important;
	border: 1px solid #fff0 !important;
	-webkit-box-shadow: 0px 0px 5px 1px #888888a1 !important;
	-moz-box-shadow: 0px 0px 5px 1px #888888a1 !important;
	box-shadow: 0px 0px 5px 1px #888888a1 !important;
}

.contact-form textarea {
	background: #ffffff !important;
	background: #ffffff !important;
	-webkit-box-shadow: 0px 0px 5px 1px #888888a1 !important;
	-moz-box-shadow: 0px 0px 5px 1px #888888a1 !important;
	box-shadow: 0px 0px 5px 1px #888888a1 !important;
}

.address-section {
	font-size: 32px !important;
	color: #505975 !important;
}

.address-section-content {
	font-size: 18px !important;
	color: #50598b !important;
}

.contact-info {
	margin-top: 40px !important;
}

.contact-info ul li {
	line-height: 20px;
	color: #50598b !important;
	font-size: 18px;
	padding-top: 15px !important;
}

.feature-box i {
	position: relative;
	top: 2px;
	font-size: 14px !important;
}

.feature-box:hover i {
	color: #ffffff;
}

/* Contact */
.text-danger {
	color: #d1812c !important;
}

.text-content p {
	color: #000000;
}

.back-picture {
	position: relative;
}

/* .back-picture::before {
  content: "";
  background-image: url(https://sambd.com/wp-content/uploads/2021/10/other-service-bg.png);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
} */

.section-block {
	padding: 0px 0px 90px 0px !important;
}

.col-md-3 {
	position: relative;
	z-index: 1;
}

/* Slider CSS */
@keyframes fade {
	from {
		opacity: 0.4;
	}

	to {
		opacity: 1;
	}
}

#slider {
	margin: 0 auto;
	width: 100%;
	overflow: hidden;
}

.slides {
	overflow: hidden;
	animation-name: fade;
	animation-duration: 1s;
	display: none;
}

img {
	width: 100%;
}

#dot {
	margin: 0 auto;
	text-align: center;
	background-color: #e1dbdb;
}

.dot {
	display: inline-block;
	border-radius: 50%;
	background: #d3d3d3;
	padding: 8px;
	margin: 10px 5px;
}

.active {
	background: black;
}

img {
	max-width: 100% !important;
	height: auto;
	-webkit-transition-duration: 0.3s;
	transition-duration: 0.3s;
}

@media (max-width: 567px) {
	#slider {
		width: 100%;
	}
}

.custom-dot.active span {
	background: #000;
}

.form-control {
	box-shadow: 0px 0px 5px 1px #888888a1 !important;
	border-radius: 7px !important;
	padding: 13px 12px !important;
}

.owl-item {
	background: transparent;
}

.custom-lavel {
	padding-top: 7px;
	padding-bottom: 7px;
}
</style>
